import type { ParamMatcher } from '@sveltejs/kit'

export const match: ParamMatcher = (param) => {
  return [
    'kontakt',
    'impressum',
    'datenschutz',
    'datenschutz-eintrittskartenio',
    'agb',
    'agb-eintrittskartenio',
    'cookies',
  ].includes(param)
}
